import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Section, Text, Title } from "../../components/Core";
import { PrivacyList, Highlight } from "./Common";

const Definitions = () => (
  <>
    <Section className="pt-0 pb-5">
      <Container>
        <Row className="justify-content-center mb-3">
          <Col>
            <Title variant="quote" className="mb-2">
              Interpretation and Definitions
            </Title>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col>
            <Title variant="card">
              Interpretation
            </Title>
            <Text>
              The words of which the initial letter is capitalized have meanings defined under the following conditions.
              The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
            </Text>
            <Title variant="card" mt={4}>
              Definitions
            </Title>
            <Text>
              For the purposes of this Privacy Policy:
            </Text>
            <PrivacyList>
              <li><Highlight>Account</Highlight> means a unique account created for You to access our Service or parts of our Service.</li>
              <li>
                <Highlight>Affiliate</Highlight> means an entity that controls, is controlled by or is under common control with a party,
                where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote
                for election of directors or other managing authority.
              </li>
              <li>
                <Highlight>Company</Highlight> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to
                SharpTree LLC, 6563 California Ave #389, Seattle WA 98136.
                For the purpose of the GDPR, the Company is the Data Controller.
              </li>
              <li><Highlight>Country</Highlight> refers to: United States</li>
              <li>
                <Highlight>Data Controller,</Highlight> for the purposes of the GDPR (General Data Protection Regulation),
                refers to the Company as the legal person which alone or jointly with others determines the purposes
                and means of the processing of Personal Data.
              </li>
              <li>
                <Highlight>Device</Highlight> means any device that can access the Service such as a computer,
                a cellphone or a digital tablet.
              </li>
              <li>
                <Highlight>Do Not Track</Highlight> (DNT) is a concept that has been promoted by US regulatory authorities,
                in particular the U.S. Federal Trade Commission (FTC), for the Internet industry to develop and implement
                a mechanism for allowing internet users to control the tracking of their online activities across websites.
              </li>
              <li>
                <Highlight>Personal Data</Highlight> is any information that relates to an identified or identifiable individual.<br />
                For the purposes for GDPR, Personal Data means any information relating to You such as a name,
                an identification number, location data, online identifier or to one or more factors specific to
                the physical, physiological, genetic, mental, economic, cultural or social identity.
              </li>
              <li><Highlight>Service</Highlight> refers to the Website.</li>
              <li>
                <Highlight>Service Provider</Highlight> means any natural or legal person who processes the data on behalf of the Company.
                It refers to third-party companies or individuals employed by the Company to facilitate the Service,
                to provide the Service on behalf of the Company, to perform services related to the Service or to assist
                the Company in analyzing how the Service is used. For the purpose of the GDPR, Service Providers are considered Data Processors.
              </li>
              <li>
                <Highlight>Third-party Social Media Service</Highlight> refers to any website or any social network website through which a User can
                log in or create an account to use the Service.
              </li>
              <li>
                <Highlight>You (or Your)</Highlight> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which
                such individual is accessing or using the Service, as applicable.<br />
                Under GDPR (General Data Protection Regulation), You can be referred to as the Data Subject
                or as the User as you are the individual using the Service.
              </li>
              <li>
                <Highlight>Website</Highlight> means the website from which you clicked through to this Privacy Policy.
              </li>
            </PrivacyList>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Definitions;
